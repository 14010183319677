.overlay {
    position: fixed;
    transition-property: all;
    transition-duration: 100ms;
    background: var(--overlay);
    inset: 0;
    display: grid;
    padding-block-start: 30vh;
    overflow-y: auto;
    z-index: 20;
}

/*  VARIANTS */
.base {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: grid;
    align-items: center;
    justify-items: center;
    width: 90vw;
    background-color: var(--color-brand-cement);
    border-radius: unset;
}

.default {
    max-width: 49.563rem;
}

@media screen and (max-width: 991px) {
    .default {
        max-width: 47rem;
    }
}

/* OTHER STYLES */
.content[data-state="open"] {
    animation: fadeIn 200ms forwards, slideInFromTop 200ms forwards;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slideInFromTop {
    0% {
        transform: translateY(-10%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes zoomIn {
    0% {
        transform: scale(0.9);
    }
    100% {
        transform: scale(1);
    }
}