.dialogContent {
    display: flex;
}

.image {
    display: flex;
    flex: 1 0 50%;
    position: relative;
}

.image > img {
    width: 100%;
    flex: 1;
}

.content {
    flex: 1 0 50%;
    display: flex;
    flex-direction: column;
    padding: var(--spacing-size-nine) var(--spacing-size-seven) var(--spacing-size-seven) var(--spacing-size-seven);
    position: relative;
}

.header {
    padding-bottom: var(--spacing-size-five);
}

.close {
    position: absolute;
    top: 24px;
    right: 24px;
}

.closeIcon {
    background-color: white;
    padding: var(--spacing-size-two) var(--spacing-size-three);
    border-radius: var(--border-radius-lg);
}

.button {
    margin-top: auto;
    display: flex;
    justify-content: flex-start;
}

.desktopOnly {
    display: block;
}

.mobileOnly {
    display: none;
}

@media screen and (max-width: 991px) {
    .dialogContent {
        flex-direction: column;
    }

    .content {
        padding: var(--spacing-size-two) var(--spacing-size-five) var(--spacing-size-five) var(--spacing-size-five);
        width: 100%;
    }

    .header {
        padding-bottom: var(--spacing-size-five);
    }

    .desktopOnly {
        display: none;
    }

    .mobileOnly {
        display: block;
    }

    .image {
        position: relative;
    }
    
    .button {
        margin-top: var(--spacing-size-five);
    }
}